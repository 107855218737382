import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { BackendService } from '../../../../shared/services/backend.service';
import { formattedSteps } from '../../common/steps';
import { UprData } from 'src/app/shared/components/upr-modal/upr-modal.component';

@Injectable({
  providedIn: 'root'
})
export class WorkInstructionsUnitService {
  unitSteps = new BehaviorSubject<any>(null);
  uprs: UprData[] = [];
  innerSteps = [];
  iterativeNumber?: number;

  private buildUrl(unit: string, param?: string, id?: string): string {
    const baseUrl = `units/${unit}/birth-certificate`;
    if (param) {
      return `${baseUrl}?${param}=${id}`;
    }
    return baseUrl;
  }

  constructor(private backend: BackendService) {}

  emitValues() {
    this.unitSteps.next(this.innerSteps);
  }

  getAll(unit: string) {
    this.getSteps(unit).subscribe(data => {
      if (data) {
        this.innerSteps = [...data];
        this.emitValues();
      }
    });
  }

  getSteps(unit: string) {
    this.iterativeNumber = null;
    // "617ff5473276301c80a87fb9" QCStationId
    return this.backend.get(this.buildUrl(unit)).pipe(
      map(data => {
        if (data) {
          this.uprs = data.uprs;
          this.iterativeNumber = data.iterativeNumber;
          const steps = formattedSteps(data);
          return steps;
        }
      })
    );
  }

  getStepPictures(unit: string, pictureNames: string[]) {
    const url = `units/${unit}/birth-certificate/pictures?${pictureNames
      .map(n => `pictureNames=${n}`)
      .join('&')}`;
    return this.backend.get(url);
  }

  updateSteps(unit: string, stepId: string, body: any) {
    return this.backend.post(this.buildUrl(unit, 'step', stepId), body);
  }

  updateComment(unit: string, stepId: string, body: any) {
    const url = `units/${unit}/birth-certificate/comment?step=${stepId}`;
    return this.backend.post(url, body);
  }

  updatePictures(unit: string, stepId: string, body: any) {
    const url = `units/${unit}/birth-certificate/pictures?step=${stepId}`;
    return this.backend.post(url, body);
  }

  updateBypassInfo(unit: string, stepId: string, qcBypass: boolean) {
    const url = `units/${unit}/birth-certificate/Qc-Bypass?step=${stepId}&bypass=${qcBypass}`;
    return this.backend.post(url, null);
  }

  deletePictureFromStep(unit: string, stepId: string, pictureName: string) {
    const url = `units/${unit}/birth-certificate?step=${stepId}&pictureName=${pictureName}`;
    return this.backend.delete(url);
  }

  upsertUpr(unit: string, body: any) {
    const url = `units/${unit}/birth-certificate/upsertUpr`;
    return this.backend.post(url, body);
  }

  changeUprState(unit: string, body: any) {
    const url = `units/${unit}/birth-certificate/changeUprState`;
    return this.backend.post(url, body);
  }
}
