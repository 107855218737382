import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-option-selection-panel',
  templateUrl: './option-selection-panel.component.html',
  styleUrls: ['./option-selection-panel.component.scss']
})
export class OptionSelectionPanelComponent {
  @Input() title: string = '';
  @Input() options: string[] = [];
  @Input() name: string = '';
  @Input() isExpanded: boolean = false;
  @Input() selectedValue: number = 0;

  @Output() expandedChange = new EventEmitter<boolean>();
  @Output() selectedValueChange = new EventEmitter<number>();

  togglePanel() {
    this.expandedChange.emit(this.isExpanded);
  }

  getInputId(index: number): string {
    return `${this.name}-${index}`;
  }

  onSelectionChange(value: number) {
    this.selectedValueChange.emit(this.selectedValue);
  }
}
