import { Injectable } from '@angular/core';
import { LanguageSelectorService } from '../components/work-instructions/services/language-selector.service';
import EnglishDictionary from '../../../assets/i18n/en.json';
import SpanishDictionary from '../../../assets/i18n/es.json';

interface MultilanguageString {
  En: string;
  Es: string;
}

export enum TranslatorKey {
  UNIT_IS_BLOCKED_AT_STATION
}

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {
  constructor(private languageSelectorService: LanguageSelectorService) { }

  currentLanguage: string;
  private translations: Map<TranslatorKey, MultilanguageString> = new Map<
    TranslatorKey,
    MultilanguageString
  >([
    [
      TranslatorKey.UNIT_IS_BLOCKED_AT_STATION,
      {
        En: 'UNIT IS BLOCKED AT STATION',
        Es: 'LA UNIDAD ESTÁ BLOQUEADA EN LA ESTACIÓN'
      }
    ]
  ]);

  translate(key: TranslatorKey): string {
    this.currentLanguage = this.languageSelectorService.currentLanguage.getValue();
    return this.currentLanguage === 'Es'
      ? (this.translations.get(key)?.Es as string)
      : (this.translations.get(key)?.En as string);
  }

  translateFromDictionary(key: string): string {
    this.currentLanguage = this.languageSelectorService.currentLanguage.getValue();
    const NOT_FOUND = 'Translation not found';
    return this.currentLanguage === 'Es'
      ? (this.getNestedValue(SpanishDictionary, key) as string) ?? NOT_FOUND
      : (this.getNestedValue(EnglishDictionary, key) as string) ?? NOT_FOUND;
  }

  private getNestedValue(obj: unknown, keyPath: string): any {
    return keyPath.split('.').reduce((acc, key) => {
      return acc && acc[key];
    }, obj);
  }


}
