<div class="upr-container">
  <!-- Header -->
  <div class="upr-header">
    <h2>UPRs Management</h2>
    <app-button
      title="Add a new UPR"
      [noIcon]="true"
      [backgroundColor]="'var(--red)'"
      [fontColor]="'white'"
      (click)="onAddUpr()"
      height="35px"
    >
    </app-button>
  </div>

  <table>
    <thead style="display: none;">
      <tr>
        <th>UPR</th>
        <th>Status</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr class="upr-item" *ngFor="let upr of uprs$ | async">
        <td style="display: flex;width: max-content;">
          <div style="display: flex;">UPR {{ upr.serialNumber }}</div>
        </td>
        <td class="status" style=" width: 100%;">
          <div>
            {{ upr.status }}
          </div>
        </td>
        <td>
          <div class="button-group">
            <!-- Edit Button -->
            <app-button
              title="Edit"
              [noIcon]="true"
              [backgroundColor]="'var(--fab-component)'"
              [fontColor]="'white'"
              (click)="onEditUpr(upr, upr._id)"
              height="35px"
            >
            </app-button>

            <app-button
              *ngIf="upr.uprOn"
              title="Resolve"
              [noIcon]="true"
              [backgroundColor]="'white'"
              [fontColor]="'var(--fabbrica)'"
              height="35px"
              border="1px solid var(--fabbrica)"
              (click)="onResolveUpr(upr.serialNumber, upr._id)"
            >
            </app-button>

            <app-button
              *ngIf="!upr.uprOn"
              title="Resolved"
              [noIcon]="true"
              height="35px"
              [backgroundColor]="'var(--fabbrica)'"
              [fontColor]="'white'"
              [disabled]="true"
              (click)="onTurnOn(upr.serialNumber, upr._id)"
            >
            </app-button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <app-upr-modal (submit)="onSubmitUpr($event)"></app-upr-modal>
</div>
