<div class="header">
  <app-round-button
    icon="fas fa-tv"
    sizepx="52"
    fontSize="24px"
    shadow="none"
    (click)="goToTVScreen()"
  ></app-round-button>
  <div class="title">{{ title }}</div>
</div>
<canvas
  class="chart"
  [id]="canvasId"
  style="max-height: 350px !important;"
></canvas>
