import { flatMap } from 'lodash';
import { WorkInstructionsStepConformity } from 'src/app/shared/components/work-instructions/enums/work-instructions-step-conformity.enum';

export function formattedSteps(data) {
  const steps = [];
  const allSteps = flatMap(data.stationConfigurations, o =>
    o.steps.map(step => ({ stationId: o.stationId, ...step }))
  );
  allSteps.forEach((step, stepIndex) => {
    const stepInputs = data.stepsInputs.find(
      stepInp => stepInp.stepId === step.id
    );
    const formattedStep = {
      id: step.id,
      stationId: step.stationId,
      description: { en: step.description.en, es: step.description.es },
      index: stepIndex,
      qcBypass: stepInputs ? stepInputs.qcBypass : false,
      conformity: stepInputs
        ? stepInputs.conformity
        : WorkInstructionsStepConformity.UNKNOWN,
      isCritical: step.isCritical,
      isFinalQC: step.isFinalQC,
      comment: stepInputs ? stepInputs.comment : '',
      pictures: stepInputs ? stepInputs.pictures : [],
      inputs: []
    };
    step.inputs.forEach((input, inputIndex) => {
      const inp = {
        inputType: input.inputType,
        inputData: input.inputData,
        result: null
      };
      if (
        stepInputs ||
        inp.inputType === 'weightInput' ||
        inp.inputType === 'rfidInput'
      ) {
        switch (inp.inputType) {
          case 'multipleOptionButton':
            inp.result = inp.inputData[stepInputs.inputs[inputIndex]];
            break;
          case 'weightInput':
            inp.result = inp.inputData;
            break;
          case 'rfidInput':
            inp.result = inp.inputData;
            break;
          default:
            inp.result = stepInputs.inputs[inputIndex];
            break;
        }
      }
      formattedStep.inputs.push(inp);
    });
    steps.push(formattedStep);
  });
  return steps;
}

export function buildUrl(unit: string, param?: string, id?: string): string {
  const baseUrl = `units/${unit}/birth-certificate`;
  if (param) {
    return `${baseUrl}?${param}=${id}`;
  }
  return baseUrl;
}

export function stepsWithConformityInfo(steps: any[]) {
  // This information is stored in the steps array and must be updated
  // when the steps are modified.
  let arePreviousStepsOk = true;
  steps.forEach((step: any, i: number) => {
    step.isPreviousStepComplete =
      i === 0 || steps[i - 1].qcBypass || isStepComplete(steps[i - 1]);
    step.arePreviousStepsOk = arePreviousStepsOk;
    if (!isStepOk(step) && !step.qcBypass) {
      arePreviousStepsOk = false;
    }
  });
  return steps;
}

export function isStepComplete(step) {
  return step.conformity !== WorkInstructionsStepConformity.UNKNOWN;
}

export function isStepOk(s) {
  return (
    s.conformity === WorkInstructionsStepConformity.CONFORM ||
    s.conformity === WorkInstructionsStepConformity.NOTAPPLICABLE
  );
}

export function shouldShowBanner(
  steps: any[],
  userStation: any,
  stations: any[]
) {
  if (
    userStation == null ||
    stations == null ||
    stations.length === 0 ||
    steps == null ||
    steps.length === 0
  ) {
    return { show: false, blockedStation: null };
  }
  const firstUserStationStep: any = steps.find(
    (s: any) => s.stationId === userStation?.id
  );

  const activeStepIndex = Math.max(
    steps.findIndex(
      (s: any, i: number) =>
        i === steps.length - 1 ||
        (s.arePreviousStepsOk &&
          s.isPreviousStepComplete &&
          !(steps[s.index + 1] as any).arePreviousStepsOk &&
          !(steps[s.index + 1] as any).isPreviousStepComplete)
    ),
    0
  );
  const activeStep = steps[activeStepIndex] as any;
  const activeStepStation = stations.find(
    (s: any) => s?.id === activeStep.stationId
  );

  if (!firstUserStationStep) {
    if (activeStepStation) {
      return { show: true, blockedStation: activeStepStation.name };
    }
  }

  const isBlocked =
    userStation?.id !== activeStepStation?.id &&
    firstUserStationStep?.index > activeStepIndex;
  if (isBlocked) {
    return { show: true, blockedStation: activeStepStation.name };
  }
  return { show: false, blockedStation: null };
}
