import { Component, ElementRef, SimpleChanges, ViewChild } from '@angular/core';
import { DropdownButtonComponent } from '../dropdown-button/dropdown-button.component';
import { ValueLabel } from '../../interfaces/value-label.interface';

@Component({
  selector: 'app-autocomplete-dropdown-button',
  templateUrl: './autocomplete-dropdown-button.component.html',
  styleUrls: ['./autocomplete-dropdown-button.component.scss']
})
export class AutocompleteDropdownButtonComponent extends DropdownButtonComponent {
  searchText: string = '';
  filteredPresets: (ValueLabel & { index: number })[] = [];

  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  ngOnInit(): void {
    super.ngOnInit();
    this.filteredPresets = this.getFilteredPresets;
    if (this.value) {
      this.selectedPreset = this.selectPresetIdByValue(this.value);
    }
    this.selectedPreset = this.selectPresetIdByValue(this.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.presets) {
      this.filteredPresets = this.getFilteredPresets;
      this.selectedPreset = this.selectPresetIdByValue(this.value);
    }
  }

  get getFilteredPresets(): (ValueLabel & { index: number })[] {
    return this.presets
      .map((item, index) => ({
        ...item,
        index
      }))
      .filter(preset =>
        preset.label.toLowerCase().includes(this.searchText.toLowerCase())
      );
  }

  onSearchInput() {
    this.filteredPresets = this.getFilteredPresets;
  }

  toggleSelect() {
    super.toggleSelect();
    this.searchText = '';
  }
}
