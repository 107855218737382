export const KEYS = {
  uprReasons: 'Upr Reason (SAP)',
  reasons: 'Reason',
  discoveryArea: 'Discovery Area',
  cause: 'Cause',
  recommendedAction: 'Recommended Action',
  actionRequiredBy: 'Action Required By',
  additional: 'Additional Information'
};

const uprReasons: string[] = [
  'Missing/Rejected Glass',
  'Missing/Rejected Alumn Panel',
  'Missing/Rejected Accessories',
  'Missing/Rejected Decoration',
  'Missing/Rejected Profile',
  'Deglaze/Reglaze Test',
  'Missing/Rejected Other',
  'Will be fixed on the site (PM Call)',
  'Unit is on Hold'
];

const reasons = [
  'Defective/Extra/Missing holes or notches',
  'Design Issue',
  'Edge/Corner damage',
  'Flatness/Straightness/Twist',
  'Holes/Notches off location',
  'Materials finish defect/Contamination inside',
  'Missing Holes',
  'Surface damage/ Broken',
  'Wrong information/wrong profile',
  'Wrong Length',
  'Wrong size/wrong angle',
  'Other'
];

const discoveryAreas = ['Assembly', 'Machining', 'Receiving', 'Shipping'];
const causes = ['Internal', 'External'];
const recommendedActions = [
  'Modify',
  'Reject / Replace',
  'Rework',
  'Use as is'
];

const actionRequiredBy = [
  'Design',
  'Production',
  'Production Planning',
  'Project Manager',
  'Purchasing'
];
const priorities = ['Low', 'Normal', 'High'];
const safetyConcernOptions = ['Yes', 'No'];

export const UPR_OPTIONS = {
  uprReasons,
  reasons,
  discoveryAreas,
  causes,
  recommendedActions,
  actionRequiredBy,
  priorities,
  safetyConcernOptions
};
