import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import { KEYS, UPR_OPTIONS } from 'src/app/core/constants/upr.constants';
import { UprSuppliersService } from '../../services/upr-suppliers.service';

const DEFAULT_PRIORITY = 2;
const DEFAULT_SAFETY_CONCERN = 2;

export interface UprModalResponse {
  newSerialNumber: string;
  reasonSelected: number;
}

export interface UprData {
  _id?: string;
  serialNumber: string;
  discoveryArea: number;
  cause: number;
  recommendedAction: number;
  uprReason: number;
  reason: number;
  actionRequiredBy: number;
  priority: number;
  safetyConcern: number;
  category: string;
  ncrReference: string;
  partNcrReference: string;
  description: string;
  supplier: string;
  takenBy: string;
  qcNotes: string;
  status?: string;
  uprOn: boolean;
}

export const NULL_UPR: UprData = {
  serialNumber: '',
  discoveryArea: 0,
  cause: 0,
  recommendedAction: 0,
  uprReason: 0,
  reason: 0,
  actionRequiredBy: 0,
  priority: DEFAULT_PRIORITY,
  safetyConcern: DEFAULT_SAFETY_CONCERN,
  category: '',
  ncrReference: '',
  partNcrReference: '',
  description: '',
  supplier: '-',
  takenBy: '',
  qcNotes: '',
  status: undefined,
  uprOn: true
};

@Component({
  selector: 'app-upr-modal',
  templateUrl: './upr-modal.component.html',
  styleUrls: ['./upr-modal.component.scss']
})
export class UprModalComponent implements OnInit {
  @Output() submit = new EventEmitter<{ data: UprData; _id?: string }>();

  open(upr: UprData, _id?: string) {
    this.on = upr.uprOn;
    this._id = _id;
    this.innerSerialNumber = upr.serialNumber;
    this.selectedPriority = upr.priority || DEFAULT_PRIORITY;
    this.selectedSafetyConcern = upr.safetyConcern || DEFAULT_SAFETY_CONCERN;
    this.selectedSupplier = upr.supplier || '-';
    this.category = upr.category || '';
    this.ncrReference = upr.ncrReference || '';
    this.partNcrReference = upr.partNcrReference || '';
    this.description = upr.description || '';
    this.formInfo = {
      ...this.formInfo,
      uprReasons: {
        ...this.formInfo.uprReasons,
        selected: upr.uprReason || 0
      },
      reasons: {
        ...this.formInfo.reasons,
        selected: upr.reason || 0
      },
      discoveryArea: {
        ...this.formInfo.discoveryArea,
        selected: upr.discoveryArea || 0
      },
      cause: {
        ...this.formInfo.cause,
        selected: upr.cause || 0
      },
      recommendedAction: {
        ...this.formInfo.recommendedAction,
        selected: upr.recommendedAction || 0
      },
      actionRequiredBy: {
        ...this.formInfo.actionRequiredBy,
        selected: upr.actionRequiredBy || 0
      }
    };
    this.showModal = true;
  }

  close() {
    this.showModal = false;
  }

  formInfo = {
    uprReasons: {
      name: KEYS.uprReasons,
      expanded: false,
      options: UPR_OPTIONS.uprReasons,
      selected: 0
    },
    reasons: {
      name: KEYS.reasons,
      expanded: false,
      options: UPR_OPTIONS.reasons,
      selected: 0
    },
    discoveryArea: {
      name: KEYS.discoveryArea,
      expanded: false,
      options: UPR_OPTIONS.discoveryAreas,
      selected: 0
    },
    cause: {
      name: KEYS.cause,
      expanded: false,
      options: UPR_OPTIONS.causes,
      selected: 0
    },
    recommendedAction: {
      name: KEYS.recommendedAction,
      expanded: false,
      options: UPR_OPTIONS.recommendedActions,
      selected: 0
    },
    actionRequiredBy: {
      name: KEYS.actionRequiredBy,
      expanded: false,
      options: UPR_OPTIONS.actionRequiredBy,
      selected: 0
    },
    additional: {
      name: KEYS.additional,
      expanded: false,
      options: [],
      selected: 0
    }
  };

  suppliers = [];
  loadingSuppliers = true;
  priorities = UPR_OPTIONS.priorities;
  safetyConcernOptions = UPR_OPTIONS.safetyConcernOptions;

  disabled: boolean;
  showModal: boolean = false;
  innerSerialNumber: string;

  // The not selected option is 0,
  // make sure to add or remove 1 to the index when
  // mapping the values.
  selectedPriority = DEFAULT_PRIORITY;
  selectedSafetyConcern = DEFAULT_SAFETY_CONCERN;
  selectedSupplier = '-';
  category = '';
  ncrReference = '';
  partNcrReference = '';
  description = '';
  on = true;
  _id: string | null = null;

  constructor(
    private toastrService: ToastService,
    private uprSuppliersService: UprSuppliersService
  ) {}

  ngOnInit(): void {
    this.loadingSuppliers = true;
    this.uprSuppliersService.getSuppliers().subscribe(suppliers => {
      this.suppliers = suppliers.map(item => ({ value: item, label: item }));
      this.loadingSuppliers = false;
    });
  }

  togglePanel(panelName: keyof typeof this.formInfo) {
    this.formInfo = {
      ...this.formInfo,
      [panelName]: {
        ...this.formInfo[panelName],
        expanded: !this.formInfo[panelName].expanded
      }
    };
  }

  closePanel(closingPanel: keyof typeof this.formInfo, $event: any) {
    const nextFormInfo = {
      ...this.formInfo,
      [closingPanel]: {
        ...this.formInfo[closingPanel],
        expanded: false
      }
    };
    this.formInfo = nextFormInfo;
    const info = this.formInfo[closingPanel];
    this.toastrService.success(
      `${info.name}: ${info.options[info.selected - 1]}`
    );
  }

  openPanel(openPanel: keyof typeof this.formInfo) {
    const nextFormInfo = {
      ...this.formInfo,
      [openPanel]: {
        ...this.formInfo[openPanel],
        expanded: true
      }
    };
    this.formInfo = nextFormInfo;
  }

  closeAndOpenNext(closingPanel: keyof typeof this.formInfo, $event: any) {
    const panelKeys = Object.keys(this.formInfo);
    const currentIndex = panelKeys.indexOf(closingPanel);
    if (currentIndex === -1 || currentIndex === panelKeys.length - 1) {
      return;
    }
    const nextPanel = panelKeys[currentIndex + 1];
    this.closePanel(closingPanel, $event);
    this.openPanel(nextPanel as keyof typeof this.formInfo);
  }

  notifySelectedSupplier(selected: string) {
    this.toastrService.success(`Supplier: ${selected}`);
  }

  handleValueChange(e: any) {
    this.innerSerialNumber = e.target.value;
  }

  get isConfirmDisabled() {
    return !this.innerSerialNumber || this.formInfo.uprReasons.selected === 0;
  }

  onConfirm() {
    if (this.isConfirmDisabled) {
      return;
    }
    const formData: UprData = {
      serialNumber: this.innerSerialNumber,
      discoveryArea: this.formInfo.discoveryArea.selected,
      cause: this.formInfo.cause.selected,
      recommendedAction: this.formInfo.recommendedAction.selected,
      uprReason: this.formInfo.uprReasons.selected,
      reason: this.formInfo.reasons.selected,
      actionRequiredBy: this.formInfo.actionRequiredBy.selected,
      priority: this.selectedPriority,
      safetyConcern: this.selectedSafetyConcern,
      category: this.category,
      ncrReference: this.ncrReference,
      partNcrReference: this.partNcrReference,
      description: this.description,
      supplier: this.selectedSupplier,
      qcNotes: '',
      takenBy: '',
      uprOn: this.on
    };

    this.showModal = false;
    this.resetForm();
    this.submit.emit({ data: formData, _id: this._id });
  }

  resetForm() {
    this.innerSerialNumber = '';
    this.selectedPriority = DEFAULT_PRIORITY;
    this.selectedSafetyConcern = DEFAULT_SAFETY_CONCERN;
    this.category = '';
    this.ncrReference = '';
    this.partNcrReference = '';
    this.description = '';

    const newFormInfo = { ...this.formInfo };
    Object.keys(this.formInfo).forEach(key => {
      newFormInfo[key] = {
        ...this.formInfo[key],
        expanded: false,
        selected: 0
      };
    });
    this.formInfo = newFormInfo;
  }

  onCancel() {
    this.showModal = false;
    this.resetForm();
  }

  onSupplierChange(supplier: string) {
    this.selectedSupplier = supplier;
  }
}
