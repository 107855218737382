import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef
} from '@angular/core';
import { ValueLabel } from '../../interfaces/value-label.interface';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownButtonComponent),
      multi: true
    }
  ]
})
export class DropdownButtonComponent implements ControlValueAccessor, OnInit {
  @Input() elementId: string;
  @Input() enable: boolean = true;
  @Input() presets: ValueLabel[];
  @Output() filterChange = new EventEmitter<string>();
  @Input() value: string;
  @Input() error: boolean;
  @Input() placeholder?: string;

  isSelectOpen = false;
  selectedPreset = -1;

  ngOnInit(): void {
    this.selectedPreset = this.placeholder
      ? -1
      : this.selectPresetIdByValue(this.value);
  }

  toggleSelect() {
    if (!this.enable) {
      return;
    }
    this.isSelectOpen = !this.isSelectOpen;
    this.isSelectOpen ? this.bringToFront() : this.sendToBack();
  }

  bringToFront() {
    const dropdown = document.querySelector(
      `#${this.elementId} .select`
    ) as HTMLElement;
    const arrowButtonContainer = document.querySelector(
      `#${this.elementId} #arrow-button-container`
    ) as HTMLElement;
    dropdown.style.zIndex = '2';
    arrowButtonContainer.style.zIndex = '3';
  }

  sendToBack() {
    const dropdown = document.querySelector(
      `#${this.elementId} .select`
    ) as HTMLElement;
    const arrowButtonContainer = document.querySelector(
      `#${this.elementId} #arrow-button-container`
    ) as HTMLElement;
    dropdown.style.zIndex = '1';
    arrowButtonContainer.style.zIndex = '1';
  }

  selectPreset(presetIndex: number) {
    if (presetIndex >= 0 && presetIndex < this.presets.length) {
      this.selectedPreset = presetIndex;
      this.isSelectOpen = false;
      const newValue = this.presets[presetIndex].value;
      this.value = newValue;
      this.onChange(newValue);
      this.filterChange.emit(newValue);
      this.sendToBack();
    }
  }

  // ControlValueAccessor implementation
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: string): void {
    if (value !== this.value) {
      this.value = value;
      const presetIndex = this.selectPresetIdByValue(value);
      this.selectedPreset = presetIndex;
      this.onChange(value); // Notify Angular forms of the change
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.enable = !isDisabled;
  }

  onInputChange(event: any): void {
    const newValue = event.target.value;
    this.value = newValue;
    this.onChange(newValue);
    this.onTouch();
  }

  selectPresetIdByValue(value: string): number {
    if (!value && !this.placeholder) {
      return 0;
    }
    if (!value && this.placeholder) {
      return -1;
    }
    return this.presets.findIndex(preset => preset.value === value);
  }
}
