import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UprListInputService } from 'src/app/modules/fab-component/fabrication/services/upr-list-input.service';
import { NULL_UPR, UprData } from '../upr-modal/upr-modal.component';
import { ViewChild } from '@angular/core';
import { UprModalComponent } from '../upr-modal/upr-modal.component';
import { take } from 'rxjs/operators';
import { ConfirmService } from '../../services/confirm.service';
import { TranslatorService } from '../../services/translator.service';

@Component({
  selector: 'app-upr-list-input',
  templateUrl: './upr-list-input.component.html',
  styleUrls: ['./upr-list-input.component.scss']
})
export class UprListInputComponent {
  uprs$: Observable<UprData[]> = this.uprListInputService.uprs$;

  @ViewChild(UprModalComponent) uprModal: UprModalComponent;

  openUprModal(upr: UprData, _id?: string) {
    this.uprModal.open(upr, _id);
  }

  closeUprModal() {
    this.uprModal.close();
  }

  constructor(
    private readonly uprListInputService: UprListInputService,
    private confirmService: ConfirmService,
    private translatorService: TranslatorService
  ) {}

  onResolveUpr(serialNumber: string, _id: string) {
    this.uprListInputService.resolveUpr(serialNumber, _id);
  }
  onEditUpr(upr: UprData, _id: string) {
    this.openUprModal(upr, _id);
  }

  onAddUpr() {
    this.openUprModal(NULL_UPR);
  }

  onSubmitUpr({ data, _id }: { data: UprData; _id: string }) {
    this.uprListInputService.saveUpr(data, _id);
  }

  onTurnOn(serialNumber: string, _id: string) {
    this.confirmService
      .confirm(
        this.translatorService.translateFromDictionary('machining.uprs.header'),
        this.translatorService.translateFromDictionary(
          'machining.uprs.content'
        ),
        {
          confirmButtonText: this.translatorService.translateFromDictionary(
            'machining.yes'
          ),
          cancelButtonText: this.translatorService.translateFromDictionary(
            'machining.no'
          ),
          invertButtons: true
        }
      )
      .pipe(take(1))
      .subscribe(resultModal1 => {
        if (resultModal1) {
          this.uprListInputService.turnOnUpr(serialNumber, _id);
        }
      });
  }
}
