<app-modal
  *ngIf="showModal"
  header="UPR/DEGLAZE"
  [confirmButtonDisabled]="isConfirmDisabled"
  (cancelEmitter)="onCancel()"
  (confirmEmitter)="onConfirm()"
>
  <div class="modal-body">
    <!-- Code Input - Always visible -->
    <div class="form-group code">
      <label>Code:</label>
      <input
        id="serial-number-input"
        type="text"
        placeholder="type code number"
        [(ngModel)]="innerSerialNumber"
        (change)="handleValueChange($event)"
        [ngStyle]="{ 'background-color': showModal ? 'transparent' : 'white' }"
      />
    </div>
    <app-option-selection-panel
      [title]="formInfo.uprReasons.name"
      [options]="formInfo.uprReasons.options"
      name="uprReasons"
      [isExpanded]="formInfo.uprReasons.expanded"
      [(selectedValue)]="formInfo.uprReasons.selected"
      (expandedChange)="togglePanel('uprReasons')"
      (selectedValueChange)="closeAndOpenNext('uprReasons', $event)"
    ></app-option-selection-panel>
    <app-option-selection-panel
      [title]="formInfo.reasons.name"
      [options]="formInfo.reasons.options"
      name="reasons"
      [isExpanded]="formInfo.reasons.expanded"
      [(selectedValue)]="formInfo.reasons.selected"
      (expandedChange)="togglePanel('reasons')"
      (selectedValueChange)="closeAndOpenNext('reasons', $event)"
    ></app-option-selection-panel>

    <app-option-selection-panel
      [title]="formInfo.discoveryArea.name"
      [options]="formInfo.discoveryArea.options"
      name="discoveryArea"
      [isExpanded]="formInfo.discoveryArea.expanded"
      [(selectedValue)]="formInfo.discoveryArea.selected"
      (expandedChange)="togglePanel('discoveryArea')"
      (selectedValueChange)="closeAndOpenNext('discoveryArea', $event)"
    ></app-option-selection-panel>

    <app-option-selection-panel
      [title]="formInfo.cause.name"
      [options]="formInfo.cause.options"
      name="cause"
      [isExpanded]="formInfo.cause.expanded"
      [(selectedValue)]="formInfo.cause.selected"
      (expandedChange)="togglePanel('cause')"
      (selectedValueChange)="closeAndOpenNext('cause', $event)"
    ></app-option-selection-panel>

    <app-option-selection-panel
      [title]="formInfo.recommendedAction.name"
      [options]="formInfo.recommendedAction.options"
      name="recommendedAction"
      [isExpanded]="formInfo.recommendedAction.expanded"
      [(selectedValue)]="formInfo.recommendedAction.selected"
      (expandedChange)="togglePanel('recommendedAction')"
      (selectedValueChange)="closePanel('recommendedAction', $event)"
    ></app-option-selection-panel>

    <div class="form-group select">
      <label>Supplier:</label>
      <app-autocomplete-dropdown-button
        id="suppliers"
        [value]="selectedSupplier"
        [placeholder]="loadingSuppliers ? 'Loading...' : 'Select supplier'"
        [elementId]="'suppliers'"
        [presets]="suppliers"
        [enable]="!loadingSuppliers"
        (filterChange)="
          onSupplierChange($event);
          notifySelectedSupplier($event);
          openPanel('actionRequiredBy')
        "
      ></app-autocomplete-dropdown-button>
    </div>

    <app-option-selection-panel
      [title]="formInfo.actionRequiredBy.name"
      [options]="formInfo.actionRequiredBy.options"
      name="actionRequiredBy"
      [isExpanded]="formInfo.actionRequiredBy.expanded"
      [(selectedValue)]="formInfo.actionRequiredBy.selected"
      (expandedChange)="togglePanel('actionRequiredBy')"
      (selectedValueChange)="closeAndOpenNext('actionRequiredBy', $event)"
    ></app-option-selection-panel>

    <!-- Additional Info Panel -->
    <div class="panel">
      <div class="panel-header" (click)="togglePanel('additional')">
        <span>{{ formInfo.additional.name }}</span>
        <app-round-button
          *ngIf="!formInfo.additional.expanded"
          class="expand-btn"
          data-cy="toggle"
          [icon]="'fas fa-chevron-down'"
          fontSize="17px"
          backgroundColor="transparent"
          [iconColor]="'var(--menuItem-text1)'"
          shadow="none"
        ></app-round-button>
        <app-round-button
          *ngIf="formInfo.additional.expanded"
          class="expand-btn"
          data-cy="toggle"
          [icon]="'fas fa-chevron-up'"
          fontSize="17px"
          backgroundColor="transparent"
          [iconColor]="'var(--menuItem-text1)'"
          shadow="none"
        ></app-round-button>
      </div>
      <div class="panel-content" *ngIf="formInfo.additional.expanded">
        <div class="form-group">
          <label>Priority:</label>
          <div class="radio-group">
            <div
              class="radio-container"
              *ngFor="let priority of priorities; let i = index"
            >
              <input
                type="radio"
                [id]="i"
                name="priority"
                [value]="i + 1"
                [(ngModel)]="selectedPriority"
                class="large-radio"
              />
              <label [for]="i">{{ priority }}</label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label>Safety Concern:</label>
          <div class="radio-group">
            <div
              class="radio-container"
              *ngFor="let option of safetyConcernOptions; let i = index"
            >
              <input
                type="radio"
                [id]="'safety-' + i"
                name="safetyConcern"
                [value]="i + 1"
                [(ngModel)]="selectedSafetyConcern"
                class="large-radio"
              />
              <label [for]="'safety-' + i">{{ option }}</label>
            </div>
          </div>
        </div>

        <div class="form-group text-input">
          <label>Category:</label>
          <input
            type="text"
            [(ngModel)]="category"
            placeholder="Enter category"
          />
        </div>

        <div class="form-group text-input">
          <label>NCR Reference:</label>
          <input
            type="text"
            [(ngModel)]="ncrReference"
            placeholder="Enter NCR reference"
          />
        </div>

        <div class="form-group text-input">
          <label>Missing or deffective part code</label>
          <input
            type="text"
            [(ngModel)]="partNcrReference"
            placeholder="Enter missing or deffective  part code"
          />
        </div>

        <div class="form-group text-input">
          <label>Description:</label>
          <textarea
            [(ngModel)]="description"
            rows="4"
            placeholder="Enter description"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</app-modal>
