import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  ModuleWithProviders
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';

import { TranslateModule } from '@ngx-translate/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { Ng2FlatpickrModule } from 'ng2-flatpickr';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ClipboardModule } from 'ngx-clipboard';
import { SocketIoModule } from 'ngx-socket-io';
import { WebcamModule } from 'ngx-webcam';

import { TextFieldModule } from '@angular/cdk/text-field';
import { BomButtonSectionComponent } from './components/bom-section/bom-button-section/bom-button-section.component';
import { BomToggleButtonComponent } from './components/bom-section/bom-button-section/bom-toggle-button/bom-toggle-button.component';
import { BomListDropdownSectionComponent } from './components/bom-section/bom-list-section/bom-list-dropdown-section/bom-list-dropdown-section.component';
import { BomListPartItemComponent } from './components/bom-section/bom-list-section/bom-list-dropdown-section/bom-list-part-item/bom-list-part-item.component';
import { BomListSectionComponent } from './components/bom-section/bom-list-section/bom-list-section.component';
import { BomSectionComponent } from './components/bom-section/bom-section.component';
import { BomTreeItemContainerComponent } from './components/bom-section/bom-tree-section/bom-tree-item-container/bom-tree-item-container.component';
import { BomTreeIndenterComponent } from './components/bom-section/bom-tree-section/bom-tree-item-container/bom-tree-item-section/bom-tree-indenter/bom-tree-indenter.component';
import { BomTreeItemSectionComponent } from './components/bom-section/bom-tree-section/bom-tree-item-container/bom-tree-item-section/bom-tree-item-section.component';
import { BomTreeSectionComponent } from './components/bom-section/bom-tree-section/bom-tree-section.component';
import { ButtonComponent } from './components/button/button.component';
import { ButtonsComponent } from './components/buttons/buttons.component';
import { DataContainerOptionnalComponent } from './components/data-container-optionnal/data-container-optionnal.component';
import { DataContainerComponent } from './components/data-container/data-container.component';
import { FileIndicatorComponent } from './components/file-indicator/file-indicator.component';
import { FullScreenContainerComponent } from './components/full-screen-container/full-screen-container.component';
import { IngeniuSignatureComponent } from './components/ingeniu-signature/ingeniu-signature.component';
import { LoadingCogComponent } from './components/loading-cog/loading-cog.component';
import { LoadingDotsComponent } from './components/loading-dots/loading-dots.component';
import { MultiRadioButtonComponent } from './components/multi-radio-button/multi-radio-button.component';
import { OvalButtonComponent } from './components/oval-button/oval-button.component';
import { PdfContainerComponent } from './components/pdf-container/pdf-container.component';
import { QrscannerComponent } from './components/qrscanner/qrscanner.component';
import { RoundBoxComponent } from './components/round-box/round-box.component';
import { RoundButtonComponent } from './components/round-button/round-button.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { SmrCalendarControlsComponent } from './components/smr-progress/components/smr-calendar-controls/smr-calendar-controls.component';
import { SmrCalendarHeaderComponent } from './components/smr-progress/components/smr-calendar-header/smr-calendar-header.component';
import { SmrCalendarComponent } from './components/smr-progress/components/smr-calendar/smr-calendar.component';
import { SmrCategoryComponent } from './components/smr-progress/components/smr-category/smr-category.component';
import { SmrDateMarkerComponent } from './components/smr-progress/components/smr-date-marker/smr-date-marker.component';
import { SmrFloorComponent } from './components/smr-progress/components/smr-floor/smr-floor.component';
import { SmrFloorsHeaderComponent } from './components/smr-progress/components/smr-floors-header/smr-floors-header.component';
import { SmrIntranetViewerButtonComponent } from './components/smr-progress/components/smr-intranet-viewer-button/smr-intranet-viewer-button.component';
import { SmrLegendComponent } from './components/smr-progress/components/smr-legend/smr-legend.component';
import { SmrProjectComponent } from './components/smr-progress/components/smr-project/smr-project.component';
import { SmrRangeSelectorComponent } from './components/smr-progress/components/smr-range-selector/smr-range-selector.component';
import { SmrSelectorComponent } from './components/smr-progress/components/smr-selector/smr-selector.component';
import { SmrComponent } from './components/smr-progress/components/smr/smr.component';
import { DateHoverDirective } from './components/smr-progress/directives/date-hover.directive';
import { DateHoveredAreaDirective } from './components/smr-progress/directives/date-hovered-area.directive';
import { DatePositionDirective } from './components/smr-progress/directives/date-position.directive';
import { DateRangePositionDirective } from './components/smr-progress/directives/date-range-position.directive';
import { SmrAssembliesCountPipe } from './components/smr-progress/pipes/smr-assemblies-counts.pipe';
import { SmrIdPipe } from './components/smr-progress/pipes/smr-id.pipe';
import { SmrLotPipe } from './components/smr-progress/pipes/smr-lot.pipe';
import { SmrNumberPipe } from './components/smr-progress/pipes/smr-number.pipe';
import { SmrPlantPipe } from './components/smr-progress/pipes/smr-plant.pipe';
import { SmrProjectLotPipe } from './components/smr-progress/pipes/smr-project-lot.pipe';
import { SmrExcelExportService } from './components/smr-progress/services/smr-progress-excel-export.service';
import { SmrProgressComponent } from './components/smr-progress/smr-progress.component';
import { TableTitleLineComponent } from './components/table-title-line/table-title-line.component';
import { UserIconComponent } from './components/user-icon/user-icon.component';
import { PermissionsDirective } from './directives/permissions.directive';
import { AuthService } from './services/auth.service';
import { BackendService } from './services/backend.service';
import { CheckForUpdateService } from './services/check-for-update.service';
import { ClearStorageService } from './services/clear-storage.service';
import { ComponentService } from './services/component.service';
import { DocumentService } from './services/document.service';
import { ToastService } from './services/toast.service';
import { UnitService } from './services/unit.service';
import { UnitsSocketService } from './services/units-socket.service';
import { UpdatePromptComponent } from './components/update-prompt/update-prompt.component';
import { SmrExtrusionsCountPipe } from './components/smr-progress/pipes/smr-extrusions-counts.pipe';
import { SmrMachinedPartsCountPipe } from './components/smr-progress/pipes/smr-machined-parts-counts.pipe';
import { WorkInstructionsComponent } from './components/work-instructions/work-instructions.component';
import { BilingualEditableTextComponent } from './components/work-instructions/components/bilingual-editable-text/bilingual-editable-text.component';
import { EditableTextComponent } from './components/work-instructions/components/editable-text/editable-text.component';
import { MultiChoiceEditableButtonComponent } from './components/work-instructions/components/multi-choice-editable-button/multi-choice-editable-button.component';
import { GenericTypeInputComponent } from './components/work-instructions/components/generic-type-input/generic-type-input.component';
import { ConformityToggleButtonComponent } from './components/work-instructions/components/conformity-toggle-button/conformity-toggle-button.component';
import { StepComponent } from './components/work-instructions/components/step/step.component';
import { StandardInputComponent } from './components/work-instructions/components/standard-input/standard-input.component';
import { NewInputDropdownComponent } from './components/work-instructions/components/new-input-dropdown/new-input-dropdown.component';
import { ChecklistManagerComponent } from './components/work-instructions/components/checklist-manager/checklist-manager.component';
import { ChecklistInfoComponent } from './components/work-instructions/components/checklist-info/checklist-info.component';
import { RecursiveLinkerComponent } from './components/recursive-linker/recursive-linker.component';
import { LanguageToggleButtonComponent } from './components/work-instructions/components/language-toggle-button/language-toggle-button.component';
import { EditionHeaderComponent } from './components/edition-header/edition-header.component';
import { FlatIconComponent } from './components/flat-icon/flat-icon.component';
import { ModalComponent } from './components/modal/modal.component';
import { CollapsableMiniMenuComponent } from './components/collapsable-mini-menu/collapsable-mini-menu.component';
import { ModalSelectComponent } from './components/modal-select/modal-select.component';
import { ModalConfirmComponent } from './components/modal-confirm/modal-confirm.component';
import { DashboardChartsComponent } from './components/dashboard-charts/dashboard-charts.component';
import { ChartCardComponent } from './components/dashboard-charts/components/chart-card/chart-card.component';
import { PartitionedBarComponent } from './components/dashboard-charts/components/partitioned-bar/partitioned-bar.component';
import { LotSelectableItemComponent } from './components/dashboard-charts/components/lot-selectable-item/lot-selectable-item.component';
import { LotSearchBarComponent } from './components/dashboard-charts/components/lot-search-bar/lot-search-bar.component';
import { ProjectListComponent } from './components/project-list/project-list.component';
import { GenericSearchBarComponent } from './components/generic-search-bar/generic-search-bar.component';
import { ProjectItemComponent } from './components/project-list/components/project-item/project-item.component';
import { CardComponent } from './components/card/card.component';
import { ProjectPictureComponent } from './components/project-list/components/project-picture/project-picture.component';
import { DocHeadComponent } from './components/doc-head/doc-head.component';
import { LinesBarComponent } from './components/dashboard-charts/components/lines-bar/lines-bar.component';
import { SliderComponent } from './components/slider/slider.component';
import { CommentSectionComponent } from './components/comment-section/comment-section.component';
import { FramePicturesComponent } from './components/frame-pictures/frame-pictures.component';
import { ModalCameraComponent } from './components/modal-camera/modal-camera.component';
import { AmplifiedPictureComponent } from './components/amplified-picture/amplified-picture.component';
import { MountainChartComponent } from './components/dashboard-charts/components/mountain-chart/mountain-chart.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { KpiWebsocketService } from './services/kpi-websocket.service';
import { SquareFootagePipe } from './components/project-list/components/project-item/squareFootage.pipe';
import { RfidInputComponent } from './components/work-instructions/components/rfid-input/rfid-input.component';
import { CalendarDateComponent } from './components/calendar-date/calendar-date.component';
import { DropdownButtonComponent } from './components/dropdown-button/dropdown-button.component';
import { PhotoInputComponent } from './components/work-instructions/components/photo-input/photo-input.component';
import { CustomCheckboxComponent } from './components/custom-checkbox/custom-checkbox.component';
import { DateRangeFilter2Component } from './components/date-range-filter/date-range-filter.component';
import { QR_VALIDATOR_TOKEN } from './tokens/qr-validator.token';
import { QrCodeService } from './services/qr-code.service';
import { ConditionalInputComponent } from './components/conditionnal-input/conditional-input.component';
import { MachineTimersWebsocketService } from './services/machine-timers-websocket.service';
import { MachineTimersIndicatorComponent } from './components/machine-timers/machine-timers-indicator/machine-timers-indicator.component';
import { MachineTimersIndicatorManagerComponent } from './components/machine-timers/machine-timers-indicator-manager/machine-timers-indicator-manager.component';
import { MachiningTimePipe } from './components/project-list/components/project-item/machining-time.pipe';
import { OptionSelectionPanelComponent } from './components/option-selection-panel/option-selection-panel.component';
import { AutocompleteDropdownButtonComponent } from './components/autocomplete-dropdown-button/autocomplete-dropdown-button.component';
import { UprModalComponent } from './components/upr-modal/upr-modal.component';
import { UprListInputComponent } from './components/upr-list-input/upr-list-input.component';

@NgModule({
  imports: [
    AngularSvgIconModule,
    ClipboardModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    Ng2FlatpickrModule,
    PdfViewerModule,
    ReactiveFormsModule,
    SocketIoModule,
    TranslateModule,
    ZXingScannerModule,
    ModalModule.forRoot(),
    TextFieldModule,
    WebcamModule
  ],
  declarations: [
    BomButtonSectionComponent,
    BomListDropdownSectionComponent,
    BomListPartItemComponent,
    BomListSectionComponent,
    BomToggleButtonComponent,
    BomTreeIndenterComponent,
    BomTreeItemContainerComponent,
    BomTreeItemSectionComponent,
    BomTreeSectionComponent,
    BomSectionComponent,
    ButtonComponent,
    ButtonsComponent,
    DataContainerComponent,
    DataContainerOptionnalComponent,
    DateHoverDirective,
    DateHoveredAreaDirective,
    DatePositionDirective,
    DateRangePositionDirective,
    FileIndicatorComponent,
    FullScreenContainerComponent,
    IngeniuSignatureComponent,
    LoadingCogComponent,
    LoadingDotsComponent,
    MultiRadioButtonComponent,
    OvalButtonComponent,
    PermissionsDirective,
    PdfContainerComponent,
    QrscannerComponent,
    RoundBoxComponent,
    RoundButtonComponent,
    SearchBarComponent,
    SmrAssembliesCountPipe,
    SmrCalendarComponent,
    SmrCalendarControlsComponent,
    SmrCalendarHeaderComponent,
    SmrCategoryComponent,
    SmrComponent,
    SmrDateMarkerComponent,
    SmrExtrusionsCountPipe,
    SmrFloorComponent,
    SmrFloorsHeaderComponent,
    SmrIdPipe,
    SmrLegendComponent,
    SmrLotPipe,
    SmrMachinedPartsCountPipe,
    SmrNumberPipe,
    SmrPlantPipe,
    SmrProgressComponent,
    SmrProjectLotPipe,
    SmrRangeSelectorComponent,
    TableTitleLineComponent,
    UpdatePromptComponent,
    UserIconComponent,
    SmrCalendarControlsComponent,
    MultiRadioButtonComponent,
    LoadingDotsComponent,
    SmrSelectorComponent,
    SmrIntranetViewerButtonComponent,
    SmrProjectComponent,
    WorkInstructionsComponent,
    BilingualEditableTextComponent,
    EditableTextComponent,
    MultiChoiceEditableButtonComponent,
    GenericTypeInputComponent,
    ConformityToggleButtonComponent,
    StepComponent,
    StandardInputComponent,
    NewInputDropdownComponent,
    ChecklistManagerComponent,
    ChecklistInfoComponent,
    RecursiveLinkerComponent,
    LanguageToggleButtonComponent,
    EditionHeaderComponent,
    FlatIconComponent,
    ModalComponent,
    CollapsableMiniMenuComponent,
    ModalSelectComponent,
    ModalConfirmComponent,
    DashboardChartsComponent,
    ChartCardComponent,
    PartitionedBarComponent,
    LotSelectableItemComponent,
    LotSearchBarComponent,
    ProjectListComponent,
    GenericSearchBarComponent,
    ProjectItemComponent,
    CardComponent,
    ProjectPictureComponent,
    DocHeadComponent,
    LinesBarComponent,
    SliderComponent,
    CommentSectionComponent,
    FramePicturesComponent,
    ModalCameraComponent,
    AmplifiedPictureComponent,
    MountainChartComponent,
    CalendarComponent,
    SquareFootagePipe,
    RfidInputComponent,
    CalendarDateComponent,
    DropdownButtonComponent,
    PhotoInputComponent,
    CustomCheckboxComponent,
    DateRangeFilter2Component,
    ConditionalInputComponent,
    MachineTimersIndicatorComponent,
    MachineTimersIndicatorManagerComponent,
    MachiningTimePipe,
    OptionSelectionPanelComponent,
    AutocompleteDropdownButtonComponent,
    UprModalComponent,
    UprListInputComponent
  ],
  providers: [
    CheckForUpdateService,
    UnitsSocketService,
    KpiWebsocketService,
    MachineTimersWebsocketService,
    { provide: Window, useValue: window },
    SmrExcelExportService,
    { provide: QR_VALIDATOR_TOKEN, useClass: QrCodeService }
  ],
  exports: [
    ButtonComponent,
    ButtonsComponent,
    BomSectionComponent,
    DataContainerComponent,
    DataContainerOptionnalComponent,
    FileIndicatorComponent,
    FullScreenContainerComponent,
    IngeniuSignatureComponent,
    LoadingCogComponent,
    OvalButtonComponent,
    PermissionsDirective,
    PdfContainerComponent,
    QrscannerComponent,
    RoundBoxComponent,
    RoundButtonComponent,
    SearchBarComponent,
    SmrCalendarComponent,
    SmrCalendarHeaderComponent,
    SmrCategoryComponent,
    SmrComponent,
    SmrFloorComponent,
    SmrFloorsHeaderComponent,
    SmrLegendComponent,
    SmrProgressComponent,
    TableTitleLineComponent,
    UpdatePromptComponent,
    UserIconComponent,
    EditableTextComponent,
    BilingualEditableTextComponent,
    MultiChoiceEditableButtonComponent,
    GenericTypeInputComponent,
    ConformityToggleButtonComponent,
    StepComponent,
    StandardInputComponent,
    NewInputDropdownComponent,
    ChecklistManagerComponent,
    ChecklistInfoComponent,
    RecursiveLinkerComponent,
    LanguageToggleButtonComponent,
    EditionHeaderComponent,
    FlatIconComponent,
    ModalComponent,
    CollapsableMiniMenuComponent,
    ModalSelectComponent,
    DashboardChartsComponent,
    ChartCardComponent,
    PartitionedBarComponent,
    LotSelectableItemComponent,
    LotSearchBarComponent,
    GenericSearchBarComponent,
    ProjectListComponent,
    ProjectItemComponent,
    CardComponent,
    ProjectPictureComponent,
    DocHeadComponent,
    CommentSectionComponent,
    SliderComponent,
    CalendarComponent,
    CalendarDateComponent,
    DropdownButtonComponent,
    CustomCheckboxComponent,
    DateRangeFilter2Component,
    ConditionalInputComponent,
    MachineTimersIndicatorManagerComponent,
    AutocompleteDropdownButtonComponent,
    UprModalComponent,
    UprListInputComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        AuthService,
        BackendService,
        ClearStorageService,
        ComponentService,
        DocumentService,
        ReactiveFormsModule,
        ToastService,
        UnitService
      ]
    };
  }

  constructor(private checkForUpdates: CheckForUpdateService) {}
}
