import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class UprSuppliersService {
  private readonly apiUrl = 'birth-certificate/suppliers';

  constructor(private readonly backendService: BackendService) {}

  getSuppliers() {
    return this.backendService
      .get(this.apiUrl)
      .pipe(map((res: any) => res.suppliers.map(item => item.trim())));
  }
}
