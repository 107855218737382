import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  NULL_UPR,
  UprData
} from 'src/app/shared/components/upr-modal/upr-modal.component';
import { LineSelectorService } from 'src/app/shared/components/work-instructions/services/line-selector.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { WorkInstructionsUnitService } from '../../work-instructions/services/work-instructions-unit.service';

function mapKeysToUpperCase(obj) {
  return Object.keys(obj).reduce((acc, key) => {
    const upperKey = key.charAt(0).toUpperCase() + key.slice(1);
    acc[upperKey] = obj[key];
    return acc;
  }, {});
}

@Injectable({
  providedIn: 'root'
})
export class UprListInputService {
  private uprs: UprData[] = [];

  uprs$ = new BehaviorSubject(this.uprs);
  loading$ = new BehaviorSubject(false);

  constructor(
    private lineService: LineSelectorService,
    private unitService: WorkInstructionsUnitService,
    private toastrService: ToastService,
    private router: Router
  ) {}

  private get unit(): string | null {
    return this.router.url.split('/')[3] || null;
  }

  resolveUpr(uprSerialNumber: string, _id: string) {
    this.postChangeUprState(uprSerialNumber, _id, false);
  }

  turnOnUpr(uprSerialNumber: string, _id: string) {
    this.postChangeUprState(uprSerialNumber, _id, true);
  }

  setUprs(uprs: UprData[]) {
    this.uprs = uprs;
    this.uprs$.next(this.uprs);
  }

  saveUpr(upr: UprData, _id: string) {
    this.postUpsertUpr(upr.serialNumber, upr, _id);
  }

  private postChangeUprState(serialNumber: string, _id: string, on: boolean) {
    this.lineService.subject.pipe(take(1)).subscribe(ln => {
      const body = {
        SerialNumber: serialNumber,
        LineNumber: ln.id,
        On: on
      };
      this.unitService
        .changeUprState(this.unit, mapKeysToUpperCase(body))
        .subscribe(data => {
          if (data.success) {
            this.toastrService.success(
              on ? 'UPR back to open' : 'UPR Resolved'
            );
            this.updateList(_id, body as any, on);
          } else {
            this.toastrService.success('Failed to resolve UPR');
          }
        });
    });
  }

  private postUpsertUpr(serialNumber: string, uprData: UprData, _id: string) {
    this.lineService.subject.pipe(take(1)).subscribe(ln => {
      const uprBody = {
        ...uprData,
        SerialNumber: serialNumber,
        LineNumber: ln.id,
        _id
      };

      this.unitService
        .upsertUpr(this.unit, mapKeysToUpperCase(uprBody))
        .subscribe(data => {
          if (data.success) {
            this.toastrService.success('UPR Resolved');
            this.updateList(_id, uprData, uprData.uprOn);
          } else {
            this.toastrService.success('Failed to resolve UPR');
          }
        });
    });
  }

  private updateList(_id: string, uprBody: UprData | null, uprState: boolean) {
    const existingUpr = this.uprs.find(u => u._id === _id);
    const getUprStatus = upr => {
      if (upr.UprOffDate != null) {
        return 'Resolved';
      }
      if (upr.UprReason == 8) {
        return 'Send to site';
      }
      return 'On hold';
    };

    if (!existingUpr) {
      const upr = { ...(uprBody ?? NULL_UPR) };
      this.uprs.push({
        ...upr,
        uprOn: uprState,
        status: getUprStatus(upr)
      });
      return;
    }

    this.uprs = this.uprs.map(upr => {
      if (upr._id === _id) {
        const newUpr = { ...upr, ...uprBody };
        return {
          ...newUpr,
          uprOn: uprState,
          status: getUprStatus(newUpr)
        };
      }
      return upr;
    });

    this.uprs$.next(this.uprs);
  }
}
