export enum Functionality {
  'TEST' = 'test',
  'Report' = 'Report',
  'Planification' = 'Planification',
  'Unit - Check parts' = 'Unit - Check parts',
  'Unit - Check parts - Set' = 'Unit - Check parts - Set',
  'Unit - Work instructions' = 'Unit - Work instructions',
  'Unit - Work instructions - Set' = 'Unit - Work instructions - Set',
  'Unit - Work instructions - Unit validation' = 'Unit - Work instructions - Unit validation',
  'Unit - Set installation status' = 'Unit - Set installation status',
  'Document' = 'Document',
  'Work instructions management' = 'Work instructions management',
  'SMR Manager' = 'SMR Manager',
  'Machining Entry Admin' = 'Machining Entry Admin',
  'Machining Entry Access' = 'Machining Entry Access',
  'On site' = 'On site',
  'Projects' = 'Projects'
}
