<div id="general-table" [ngClass]="classGrid" (click)="clickOnScreen()">
  <app-menu
    *ngIf="(deviceService.isMobile | async) && showMenu"
    id="main-menu"
    [ngClass]="'menu-over'"
  ></app-menu>
  <app-menu
    *ngIf="(deviceService.isMobile | async) === false"
    id="main-menu"
    [ngClass]="'menu-parralel'"
  ></app-menu>
  <app-kpi-indicators-manager
    *ngIf="showKpi"
    (click)="clickOutOfTheMenu()"
  ></app-kpi-indicators-manager>
  <div id="main-app" (click)="clickOutOfTheMenu()">
    <app-update-prompt></app-update-prompt>
    <router-outlet></router-outlet>
  </div>
</div>
