<div class="panel">
  <div class="panel-header" (click)="togglePanel()">
    <span>{{ title }}</span>
    <app-round-button
      class="expand-btn"
      *ngIf="!isExpanded"
      data-cy="toggle"
      icon="fas fa-chevron-down"
      fontSize="17px"
      backgroundColor="transparent"
      [iconColor]="'var(--menuItem-text1)'"
      shadow="none"
    ></app-round-button>
    <app-round-button
      class="expand-btn"
      *ngIf="isExpanded"
      data-cy="toggle"
      icon="fas fa-chevron-up"
      fontSize="17px"
      backgroundColor="transparent"
      [iconColor]="'var(--menuItem-text1)'"
      shadow="none"
    ></app-round-button>
  </div>
  <div class="panel-content" *ngIf="isExpanded">
    <div class="form-group">
      <div class="radio-group">
        <div
          class="radio-container"
          *ngFor="let option of options; let i = index"
        >
          <input
            type="radio"
            [id]="getInputId(i)"
            [name]="name"
            [value]="i + 1"
            [(ngModel)]="selectedValue"
            (ngModelChange)="onSelectionChange($event)"
            class="large-radio"
          />
          <label [for]="getInputId(i)">{{ option }}</label>
        </div>
      </div>
    </div>
  </div>
</div>
