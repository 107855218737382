<div class="calendar-container">
  <app-calendar
    [initialDate]="initDate"
    [endDate]="endDate"
    (filterChange)="setDates($event)"
  ></app-calendar>
</div>

<app-draggable-chart
  *ngFor="let line of linesData"
  [title]="line.name"
  [lineNumber]="line.lineNumber"
  [(goals)]="line.goals"
  [processedUnits]="line.processedUnits"
  [labels]="line.labels"
  (goalChanged)="onGoalChanged($event, line.lineNumber)"
>
</app-draggable-chart>
